<template>
    <v-container>
        <h1 class="header">List of your Surveys</h1>
        <v-data-table
                :headers="headers"
                :items="Surveys"
                :items-per-page="15"
                item-key="id"
                @click:row="selectRow"
                class="elevation-24" 
            >
            </v-data-table>
            <p></p>
            <v-btn class="synch" block dark color="oug" min-width="250" light depressed @click="synch_surveys" elevation="24">
              Synch Surveys with OUG
            </v-btn>
    </v-container>
</template>

<script>
import db from '../Data/Database';
import { localStore } from '../Data/DBActions';

export default {
    data () {
        return {
        headers: [
            {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
            },
            { text: 'Title', value: 'title' },
            { text: 'Description', value: 'description' },
            { text: 'Team', value: 'team' },
            { text: 'Status', value: 'status' },
        ],
        Surveys: []
        }
    },
    created () {
        db.surveys.toArray(result => this.loadSurveys(result))
    },
    methods: {
        loadSurveys (result) {
            this.Surveys = result
        },
        synch_surveys() {
            this.$store
            .dispatch("load_surveys")
            .then(() => {
                this.$router.push("/");
            })
            .catch((err) => {
                this.error = err.response.data.message;
            });
        },
        selectRow (survey) {
                // Set localStore 
                localStore("currentSurvey", JSON.stringify(survey))
                db.currentState.update(1, {Survey: survey.id}).then(function (updated) {
                    updated
                        ?
                        console.log("Game number " + survey.id + " was updated to currenState") :
                        console.log("Game number " + survey.id + " failed to load to currenState");
                    });
                
                return this.$router.push('/')
                
            },
    },
    computed: {
    },    
}
</script>

<style lang="scss" scoped>

.header {
  text-align: center;
  padding: 1em;
  font-size: 30px;
}

.synch {
  text-align: center;
  padding: 5em;
}

</style>