

export function localStore(key, value) {
    let retVal = "";
    value
        ?
        localStorage.setItem(key, value) :
        (retVal = localStorage.getItem(key));
    return retVal;
}




